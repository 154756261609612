<template>
	<div>
		<user-list-add-new
			:is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
			:role-options="roleOptions"
			:plan-options="planOptions"
			@refetch-data="refetchData"
		/>
		<!-- Table Container Card -->
		<b-card no-body class="mb-1">
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
							@input="search(null, null, current_page, perpage)"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>

					<!-- Search -->
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<b-form-input
								v-model="search_input"
								class="d-inline-block mr-1"
								placeholder="Usuario..."
								debounce="500"
							/>
							<!-- @click="isAddNewUserSidebarActive = true" -->
							<b-button variant="primary" to="/users-route/create" v-if="isAdmin">
								<span class="text-nowrap">Agregar usuario</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refUserListTable"
				class="position-relative"
				:items="data"
				:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
				sticky-header="75vh"
				:fields="arrayColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				responsive
				small
				:busy="isBusy"
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<!-- Column: User -->
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				<template #cell(user)="data" class="">
					<b-media vertical-align="center" class="body-cell" style="width: 100%">
						<template #aside>
							<b-avatar
								size="32"
								:src="data.item.avatar"
								:text="avatarText(data.item.name_user)"
								:variant="`light-${resolveUserRoleVariant(JSON.parse(data.item.u_roles)[0].id_role)}`"
							/>
							<!-- :to="{ name: 'apps-users-view', params: { id: data.item.id } }" -->
						</template>
						<!-- <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >

            </b-link> -->
						<span class="font-weight-bold d-block text-nowrap">
							{{ data.item.name_user }}
						</span>

						<small class="text-muted">@{{ data.item.email }}</small>
					</b-media>
				</template>
				<template #cell(roles)="data">
					<div class="grid-roles">
						<b-badge
							variant="primary"
							class="badge-glow text-capitalize"
							v-for="role in JSON.parse(data.item.u_roles)"
							:key="role.id_role"
						>
							{{ role.name_role }}
						</b-badge>
					</div>
				</template>

				<!-- Column: Status -->
				<template #cell(status)="data">
					<b-badge
						pill
						:variant="`light-${resolveUserStatusVariant(data.item.status)}`"
						class="text-capitalize"
					>
						{{ data.item.status }}
					</b-badge>
				</template>

				<template #cell(privilege)="data">
					<b-badge v-if="data.item.privilege" pill :variant="`primary`" class="text-capitalize">
						{{ data.item.privilege }}
					</b-badge>
				</template>

				<template #cell(name_create)="data">
					<p v-html="data.item.name_create"></p>
				</template>

				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="d-flex align-items-center justify-content-center">
						<router-link
							:to="{
								name: 'editar-user',
								params: { user_id: data.item.id },
							}"
						>
							<feather-icon icon="EditIcon" class="cursor-pointer mr-1 text-info" size="20" />
						</router-link>

						<b-icon
							font-scale="1.8"
							icon="list-task"
							class="cursor-pointer mr-1 text-info"
							aria-hidden="true"
							@click="openModalAccesHistory(data.item.id)"
						></b-icon>
						<b-button
							size="sm"
							variant="flat-primary"
							pill
							v-if="data.item.u_roles.includes('Invitado')"
							@click="openGuestSpecimenModal(data.item)"
							v-b-tooltip.hover.top="`Invitado - Ejemplares`"
							class="d-flex align-items-center"
						>
							{{ data.item.guest ? data.item.guest.counter : 0 }}
							<v-icon name="gi-rooster" scale="1" class="text-primary" />
						</b-button>
					</div>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
			

				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<GuestSpecimenModal
			:show="showGuestSpecimenModal"
			:info="infoGuestSpecimenModal"
			@closing="showGuestSpecimenModal = false"
			@refresh="reloadTableUser"
		/>
		<ModalAccesHistory v-if="showAccesModal" :idUserAccessHistory="idUserAccessHistory" />
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
} from "bootstrap-vue"
import vSelect from "vue-select"
import store from "@/store"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
// import UsersListFilters from './UsersListFilters.vue'
import useUsersList from "./useUsersList"
import userStoreModule from "./userStoreModule"
import UserListAddNew from "../../apps/user/users-list/UserListAddNew.vue"
import axios from "@/axios"
import GuestSpecimenModal from "./GuestSpecimenModal.vue"
import ModalAccesHistory from "@/views/amg/users/accessHistory/component/ModalAccesHistory.vue"

export default {
	data() {
		return {
			arrayColumns: [
				{
					key: "user",
					label: "Usuario",
					class: "text-center d-flex flex-column",
				},
				// { key: "name", label: "Nombres y Apellidos", class: "text-center" },
				// { key: "email", label: "Usuario", class: "text-center" },
				{ key: "roles", label: "Rol(es)", class: "text-center " },
				{ key: "privilege", label: "Cargo", class: "text-center " },
				{ key: "status", label: "Estado", class: "text-center " },
				{
					key: "name_create",
					label: "Creado por",
					class: "text-center ",
				},
				{
					key: "t_access_log",
					label: "Último acceso",
					class: "text-center ",
				},
				{ key: "actions", label: "Acciones", class: "text-center " },
			],
			data_sessions: [],
			modal_sessions: false,
			data: [],
			edit: [],
			list_users: true,
			create_update: false,
			disable: {
				save: false,
			},
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			showGuestSpecimenModal: false,
			infoGuestSpecimenModal: {
				guestId: null,
			},
			showAccesModal: false,
			idUserAccessHistory: 0,
		}
	},
	components: {
		UserListAddNew,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
		GuestSpecimenModal,
		ModalAccesHistory
	},
	mounted() {
		//   this.search(null, null);
		if (this.currentUser) {
			this.search(null, null, 1, this.perpage)
		}
	},
	methods: {
		search(dato1, dato2, page, perpage) {
			this.isBusy = true
			this.dato1 = dato1
			this.dato2 = dato2
			axios
				.post("/api/get-users?page=" + page, {
					userid: this.currentUser.user_id,
					orderby: dato1 == null ? "desc" : dato1,
					order: dato2 == null ? 3 : dato2,
					campo: this.search_input == "" ? null : this.search_input,
					perpage: perpage,
				})
				.then((response) => {
					if (response.status == 200) {
						this.data = response.data.data
						this.start_page = response.data.from
						this.current_page = response.data.current_page
						this.perpage = response.data.per_page
						this.next_page = this.start_page + 1
						this.end_page = response.data.last_page
						this.total_data = response.data.total
						this.to_page = response.data.to
						this.isBusy = false
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		prev(start_p) {
			var newval = start_p - 1
			axios
				.post("/api/get-users?page=" + newval, {
					userid: this.currentUser.user_id,
					orderby: this.dato1 == null ? "desc" : this.dato1,
					order: this.dato2 == null ? 3 : this.dato2,
					campo: this.search_input == "" ? null : this.search_input,
				})
				.then((response) => {
					if (response.status == 200) {
						this.data = response.data.data
						this.start_page = response.data.current_page
						this.perpage = response.data.per_page
						this.next_page = this.start_page + 1
						this.end_page = response.data.last_page
						this.total_data = response.data.total
						if (this.start_page == this.end_page) {
							this.next_page = this.start_page
						} else {
							this.next_page = this.start_page + 1
						}
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		next(next) {
			axios
				.post("/api/get-users?page=" + next, {
					userid: this.currentUser.user_id,
					orderby: this.dato1 == null ? "desc" : this.dato1,
					order: this.dato2 == null ? 3 : this.dato2,
					campo: this.search_input == "" ? null : this.search_input,
				})
				.then((response) => {
					if (response.status == 200) {
						this.data = response.data.data
						this.start_page = response.data.current_page
						this.perpage = response.data.per_page
						this.next_page = this.start_page + 1
						this.end_page = response.data.last_page
						this.total_data = response.data.total
						if (this.start_page == this.end_page) {
							this.next_page = this.start_page
						} else {
							this.next_page = this.start_page + 1
						}
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		resetSearch() {
			this.search_input = ""
			this.search(null, null, 1, this.perpage)
		},
		closeModals() {
			this.modal_sessions = false
		},
		openmodal(id) {
			axios
				.post("/api/get-sessions-user", {
					iduser: id,
				})
				.then((response) => {
					if (response.data) {
						this.data_sessions = response.data
						this.modal_sessions = true
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		openGuestSpecimenModal(item) {
			this.infoGuestSpecimenModal.guestId = item.id
			this.showGuestSpecimenModal = !this.showGuestSpecimenModal
		},
		reloadTableUser() {
			console.log("reload")
			this.search(null, null, 1, this.perpage)
		},

		openModalAccesHistory(id) {
			console.log(id)
			this.idUserAccessHistory = id
			this.showAccesModal = !this.showAccesModal
		},
	},
	watch: {
		currentUser(newVal, oldVal) {
			this.search(null, null, 1, this.perpage)
		},
		search_input(newVal) {
			this.search(null, null, 1, this.perpage)
		},
		current_page(newVal) {
			this.search(null, null, newVal, this.perpage)
		},
	},
	setup() {
		const USER_APP_STORE_MODULE_NAME = "app-user"

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
			store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})

		const isAddNewUserSidebarActive = ref(false)

		const roleOptions = [
			{ label: "Admin", value: "admin" },
			{ label: "Author", value: "author" },
			{ label: "Editor", value: "editor" },
			{ label: "Maintainer", value: "maintainer" },
			{ label: "Subscriber", value: "subscriber" },
		]

		const planOptions = [
			{ label: "Basic", value: "basic" },
			{ label: "Company", value: "company" },
			{ label: "Enterprise", value: "enterprise" },
			{ label: "Team", value: "team" },
		]

		const statusOptions = [
			{ label: "Pending", value: "pending" },
			{ label: "Active", value: "active" },
			{ label: "Inactive", value: "inactive" },
		]

		const {
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		} = useUsersList()

		return {
			// Sidebar
			isAddNewUserSidebarActive,

			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// Filter
			avatarText,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			roleOptions,
			planOptions,
			statusOptions,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		}
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.grid-roles {
	display: flex;
	flex-direction: column;
	align-items: center;
	.badge {
		margin: 0.5rem 0;
	}
}
.body-cell {
	height: 100% !important;
}
</style>
