<template>
	<b-modal centered size="lg" title="USUARIO/ AGREGAR EJEMPLAR" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<template>
				<b-form-group label="Agregar Especimen" :invalid-feedback="optionsErr" :state="!!!optionsErr">
					<b-input-group>
						<b-form-input
							placeholder="Ingrese placa o alias"
							:state="typeof optionsErr == 'string' ? false : null"
							v-model="search"
							@keyup.enter="searchPlates"
						/>
						<b-input-group-append>
							<b-button variant="outline-primary" @click="searchPlates">
								<feather-icon icon="SearchIcon" />
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</template>
			<hr />
			<b-table-simple small sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Ejemplar</b-th>
						<b-th>Ultima modificacion</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, i) in rows" :key="row.specimen.id" :variant="colorizeRow(row.action)">
						<b-td>
							<SpecimenPlate :specimen="row.specimen" />
						</b-td>
						<b-td>
							<span v-if="row.updated_at">
								{{ row.updated_at.date | myGlobalDayWhour }}
								<br />
								<small>{{ row.updated_at.user }}</small>
							</span>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center" style="gap: 0.5rem">
								<b-button
									variant="danger"
									class="btn-icon"
									size="sm"
									@click="deleteRow(i)"
									v-if="row.action == 'none'"
								>
									<feather-icon icon="TrashIcon"
								/></b-button>
								<b-button
									variant="warning"
									class="btn-icon"
									size="sm"
									@click="modifyRow(i, 'dismiss')"
									v-else-if="row.action == 'add'"
								>
									<feather-icon icon="XIcon" />
								</b-button>
								<b-button
									variant="warning"
									class="btn-icon"
									size="sm"
									@click="modifyRow(i, 'cancel')"
									v-else-if="row.action == 'rem'"
								>
									<feather-icon icon="XIcon"
								/></b-button>
								<b-button
									variant="info"
									class="btn-icon"
									size="sm"
									v-if="row.action != 'add'"
									@click="showSpecimenTracking(row.specimen)"
								>
									<feather-icon icon="ClockIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<tr v-if="rows.length == 0">
						<b-td colspan="3"> NO HAY EJEMPLARES PARA MOSTRAR </b-td>
					</tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<div slot="modal-footer">
			<b-button variant="primary" @click="sendSpecimens" :disabled="isLoading || !modified">Guardar</b-button>
		</div>
		<!-- Tracking -->
		<b-modal hide-footer :title="`Historial de ${trackedSpecimen}`" v-model="showTracking">
			<b-table-simple small>
				<b-thead class="text-center">
					<b-tr>
						<b-th>Estado</b-th>
						<b-th>Modificado el</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in trackingRows" :key="row.updated_at.date">
						<b-td>
							<b-badge :variant="row.status_id == 1 ? 'success' : 'danger'">{{
								row.status_name
							}}</b-badge>
						</b-td>
						<b-td>
							<span>
								{{ row.updated_at.date | myGlobalDayWhour }}
								<br />
								<small>{{ row.updated_at.user }}</small>
							</span>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
		<!-- Search options -->
		<b-modal hide-footer title="Mostrando posibles placas" v-model="showOptions">
			<b-row>
				<b-col class="mb-1 text-center" sm="6" v-for="option in specimensOpts" :key="option.plate">
					<div class="cursor-pointer" @click="selectOpt(option)">
						<!-- <b-badge class="cursor-pointer" @click="selectOpt(option)">{{ option.helper }}</b-badge> -->
						<SpecimenPlate :specimen="option" style="pointer-events: none" />
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</b-modal>
</template>

<script>
import guestService from "@/services/guest.service";
import { getSpecimensToGuest } from "@/views/amg/header-bookmarks/service.header-book.js";
import _ from "lodash";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: {
			/** @type {{ new () : { guestId: number }}} */
			type: Object,
		},
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		// Tracking
		showTracking: false,
		trackedSpecimen: "",
		trackingRows: [],
		// options
		showOptions: false,
		optionsErr: null,
		search: "",
		specimensOpts: [],
	}),
	computed: {
		modified() {
			return this.rows.some((row) => row.action != "none");
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.specimensOpts = [];
			this.search = "";
			this.$emit("closing");
		},
		async getRows() {
			this.isLoading = true;
			const { data } = await guestService.getAvailableSpecimens({ guest_id: this.info.guestId });
			this.rows = data.map((item) => ({ ...item, action: "none" }));

			this.isLoading = false;
		},
		selectOpt(opt) {
			const duplicated = this.rows.some((row) => row.specimen.id == opt.id);
			if (duplicated) {
				this.showToast(
					"warning",
					"top-right",
					"Duplicado",
					"AlertCircleIcon",
					`El especimen ${opt.alias || opt.plate} ya existe en la lista.`
				);
				this.search = "";
				return;
			}
			this.rows.push({
				specimen: opt,
				updated_at: null,
				action: "add",
			});
			this.search = "";
			this.showOptions = false;
		},
		colorizeRow(action) {
			switch (action) {
				case "add":
					return "success";
				case "rem":
					return "danger";
				default:
					return "";
			}
		},
		modifyRow(idx, action) {
			switch (action) {
				case "dismiss":
					this.rows.splice(idx, 1);
					break;
				case "cancel":
					this.rows[idx].action = "none";
					break;
			}
		},
		async deleteRow(idx) {
			let specimen = this.rows[idx].specimen;
			console.log(specimen);
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se eliminara el ejemplar ${specimen.alias || specimen.plate}.`,
			});
			if (!isConfirmed) return;
			this.isLoading = true;
			this.rows[idx].action = "rem";
			let specimens = this.rows
				.filter((row) => ["none", "rem"].includes(row.action))
				.map((row) => ({
					active: row.action != "rem",
					specimen_id: row.specimen.id,
					plate: row.specimen.plate,
					action: row.action,
				}));
			const { message } = await guestService.updateAvailableSpecimens({ guest_id: this.info.guestId, specimens });
			this.rows.splice(idx, 1);
			this.showToast("success", "top-right", "Usuario invitado", "SuccessIcon", message);
			this.$emit("refresh");
			this.isLoading = false;
		},
		async sendSpecimens() {
			this.isLoading = true;
			let specimens = this.rows.map((row) => ({
				active: row.action != "rem",
				specimen_id: row.specimen.id,
				plate: row.specimen.plate,
				action: row.action,
			}));

			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se agregara acceso a ejemplares (${specimens
					.filter((it) => ["add", "rem"].includes(it.action))
					.map((it) => it.plate)
					.join(",")}).`,
			});
			if (!isConfirmed) {
				this.isLoading = false;
				return;
			}
			const { message } = await guestService.updateAvailableSpecimens({ guest_id: this.info.guestId, specimens });
			this.isLoading = false;
			this.showToast("success", "top-right", "Usuario invitado", "SuccessIcon", message);
			this.$emit("refresh");
			this.getRows();
		},
		async showSpecimenTracking(specimen) {
			this.isLoading = true;
			const { data } = await guestService.getSpecimensTracking({
				guest_id: this.info.guestId,
				specimen_id: specimen.id,
			});
			this.trackingRows = data;
			this.trackedSpecimen = specimen.alias || specimen.plate;
			this.showTracking = true;
			this.isLoading = false;
		},
		//
		async searchPlates() {
			this.optionsErr = null;
			if (this.search.length < 3) {
				this.optionsErr = "Búsqueda de al menos 3 caracteres.";
				return;
			}
			const { data } = await getSpecimensToGuest({
				name: this.search,
			});
			this.specimensOpts = data.map((item) => ({
				id: item.id,
				plate: item.plate,
				alias: item.alias,
				thumb: item.thumb,
				additional_plate: item.additional_plate,
			}));
			if (this.specimensOpts.length == 0) {
				this.showToast("warning", "top-right", "Sin resultados", "SuccessIcon", "No se encontraron resultados");
				return;
			}
			if (this.specimensOpts.length == 1) {
				this.selectOpt(this.specimensOpts[0]);
				return;
			}
			this.showOptions = true;
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getRows();
				this.isActive = val;
			}
		},
	},
};
</script>
