<template>
	<b-modal
		ref="modal-tracking-inputs-amount"
		@hidden="$emit('close')"
		:title="`Historial de accesos`"
		cancel-title="Cancelar"
		scrollable
		hide-footer
		size="xlg"
	>
		<AccessHistoryTableVue :idUser="idUserAccessHistory" />
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import AccessHistoryTableVue from "@/views/amg/users/accessHistory/component/AccessHistoryTable.vue"
export default {
	props: {
		idUserAccessHistory: {
			required: true,
		},
	},
	components: {
		AccessHistoryTableVue,
	},
	directives: { Ripple },
	mixins: [modalMixin],

	async mounted() {
		this.toggleModal("modal-tracking-inputs-amount")
	},
}
</script>

<style></style>
